define("discourse/plugins/discourse-nationalflags/wizard/components/wizard-field-national-flag", ["exports", "ember-addons/ember-computed-decorators", "wizard/lib/ajax"], function (_exports, _emberComputedDecorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = Ember.Component.extend((_dec = (0, _emberComputedDecorators.on)('init'), _dec2 = (0, _emberComputedDecorators.default)('field.value'), (_obj = {
    layoutName: 'javascripts/wizard/templates/components/wizard-field-national-flag',
    natflaglist: [],
    setup() {
      (0, _ajax.ajax)({
        url: '/natflags/flags',
        type: 'GET'
      }).then(natflags => {
        let localised_flags = [];
        localised_flags = natflags.flags.map(element => {
          return {
            code: element.code,
            pic: element.pic,
            description: I18n.t(`flags.description.${element.code}`)
          };
        }).sortBy('description');
        this.set('natflaglist', localised_flags);
      });
    },
    flagsource() {
      return this.get('field.value') == null ? 'none' : this.get('field.value');
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "flagsource", [_dec2], Object.getOwnPropertyDescriptor(_obj, "flagsource"), _obj)), _obj)));
});